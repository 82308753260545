import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  Textarea,
  FormControl,
  FormLabel,
  Flex,
  Tag,
  TagLabel,
  TagCloseButton,
  Box,
} from "@chakra-ui/react";
import { IEmail } from "@/interfaces/IEmail";
import { sendReport } from "@/services/useBriefer";
import { CustomToast } from "@/common/toast/CustomToast";

const BriefReportModal = ({ isOpen, onClose, briefReport, isEditContent, lang }) => {
  const { successToast, errorToast } = CustomToast();
  const [email, setEmail] = useState<IEmail>({
    fromAddress: "",
    toAddress: "",
    subject: "",
    body: "",
  });
  const [isSending, setIsSending] = useState(false);
  const [tags, setTags] = useState([]);

  useEffect(() => {
    if(isEditContent) {
      setEmail((prevState) => ({
        ...prevState,
        body: briefReport,
      }));
    }
  }, [isEditContent, briefReport]);

  const handleInputChange = (e) => {
    setEmail((prevState) => ({
      ...prevState,
      toAddress: e.target.value,
    }));
  };

  const handleInputKeyDown = (e) => {
    if (e.key === "Enter" || e.key === ",") {
      e.preventDefault();
      addTag(email?.toAddress.trim());
    }
  };

  const addTag = (tag) => {
    if (tag !== "" && !tags.includes(tag)) {
      setTags([...tags, tag]);
      setEmail((prevState) => ({
        ...prevState,
        toAddress: "",
      }));
    }
  };

  const removeTag = (tagToRemove) => {
    const updatedTags = tags.filter((tag) => tag !== tagToRemove);
    setTags(updatedTags);
  };

  const handleSendEmail = async () => {
    const tagsToAddress = tags.toString();
    const brieferReportModel = {
      toAddress: tagsToAddress,
      subject: email.subject,
      body: isEditContent ? "" : email.body,
      briefReport: isEditContent ? email.body : briefReport,
    };

    setIsSending(true);
    const isSent = await sendReport(brieferReportModel);
    if (isSent) {
      successToast("Briefer report", "Successfully sent.");
    } else {
      errorToast("Briefer report", "Unable to send report.");
    }
    setIsSending(false);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{lang?.ADVANCED_POST_BRIEFER_SENDREPORT || "Send report"}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl mb={4}>
            <FormLabel>{lang?.TO || "To"}</FormLabel>
            <Input
              type="email"
              value={email.toAddress}
              onChange={handleInputChange}
              onKeyDown={handleInputKeyDown}
              placeholder={lang?.ADVANCED_POST_BRIEFER_PLACEHOLDER1 || "Please type email address and press enter"}
            />
            {tags.length > 0 && (<Box
              border="1px solid #718096"
              borderRadius="md"
              p="1"
              mt={2}
            >
              {tags.map((tag) => (
                <Tag key={tag} size="md" m="1" mt={2}>
                  <TagLabel>{tag}</TagLabel>
                  <TagCloseButton onClick={() => removeTag(tag)} />
                </Tag>
              ))}
            </Box>)}
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>{lang?.ADVANCED_POST_BRIEFER_SUBJECT || "Subject"}</FormLabel>
            <Input
              type="text"
              value={email.subject}
              onChange={(e) =>
                setEmail((prevState) => ({
                  ...prevState,
                  subject: e.target.value,
                }))
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel>{isEditContent ? lang?.ADVANCED_POST_BRIEFER_CONTENT || "Content" : "Body"}</FormLabel>
            <Textarea
              value={email.body}
              onChange={(e) =>
                setEmail((prevState) => ({
                  ...prevState,
                  body: e.target.value,
                }))
              }
              rows={6}
            />
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="messenger"
            mr={3}
            onClick={handleSendEmail}
            isLoading={isSending}
            loadingText={"sending report..."}
            isDisabled={email.subject === "" || tags.length === 0}
          >
            {lang?.SEND || "Send"}
          </Button>
          <Button colorScheme="gray" onClick={onClose}>{lang?.CANCEL || "Cancel"}</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default BriefReportModal;
